<template>
  <div class="w-100 d-flex flex-wrap insights">
    <CCol cols="3" xl="3" lg="3" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="9" xl="9" lg="9" md="9" sm="9" class="text-left pt-0 pb-0">
              {{$t('sm.Sparks_sent_last_month')}}
            </CCol>
            <CCol cols="3" xl="3" lg="3" md="3" sm="3" class="text-right pt-0 pb-0">
              <i v-if="!loadingData" class="fas fa-question-circle help_icon" v-c-tooltip="{placement: 'left', content: $t('sm.Sparks_sent_last_month_help')}"/>
            </CCol>
          </CRow>       
        </CCardHeader>
        <CCardBody>
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <div v-else class="d-flex align-items-center justify-content-center">
            <div class="w-100 area_chart_container">
              <Count mode="with_description" :count="metrics.moments_sent" :countTitle="$t('sm.Unique_receivers_last_month') + ': ' + metrics.unique_recipients" :countIcon="null" class="chart_count"/>
              <AreaChart :areaChartSeries="momentsSentChartData" xAxisType="datetime" yAxisType="int" :companyPrimaryColor="companyPrimaryColor"/>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol cols="3" xl="3" lg="3" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="9" xl="9" lg="9" md="9" sm="9" class="text-left pt-0 pb-0">
              {{$t('sm.Coins_received_last_month')}}
            </CCol> 
            <CCol cols="3" xl="3" lg="3" md="3" sm="3" class="text-right pt-0 pb-0">              
              <i v-if="!loadingData" class="fas fa-question-circle help_icon" v-c-tooltip="{placement: 'left', content: $t('sm.Coins_received_last_month_help')}"/>
            </CCol>
          </CRow>       
        </CCardHeader>
        <CCardBody>
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <div v-else class="d-flex align-items-center justify-content-center">
            <div class="w-100 area_chart_container">
              <Count mode="with_description" :count="metrics.points_received" :countTitle="null" :countIcon="null" class="chart_count"/>
              <AreaChart :areaChartSeries="pointsReceivedChartData" xAxisType="datetime" yAxisType="int" :companyPrimaryColor="companyPrimaryColor"/>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol cols="6" xl="6" lg="6" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{$t('sm.Most_used_subjects')}}
            </CCol>       
          </CRow>       
        </CCardHeader>
        <CCardBody>
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <HighlightStage v-else 
                          :stageData="metrics.most_used_subjects" 
                          :scoreValue="'usage_count'"
                          :nameValue="'tag'"
                          :idValue="null"
                          :showDecimals="false"
                          :navigateTo="null">
          </HighlightStage>
        </CCardBody>
      </CCard>
    </CCol>        
  </div>  
</template>


<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import HighlightStage from '@/components/insights/common/HighlightStage.vue';
import Count from '@/components/insights/common/Count.vue';
import AreaChart from '@/components/insights/common/AreaChart.vue';

export default {
  name: 'MeasureMetrics',  
  components: {
    loadingSpinner,
    HighlightStage,
    Count,
    AreaChart
  }, 
  data() {
    return {
      loadingData: false,
      metrics: {
        subjects: [],
        moments_sent: 0,
        unique_recipients: 0       
      },
      companyPrimaryColor: null,
      momentsSentChartData: [],
      uniqueRecipientsChartData: [],
      pointsReceivedChartData: [],   
    }
  },
  methods: {
    getMomentMetrics() {
      // Start the loader
      this.loadingData = true;
      // Get the metrics
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/loyalty/moments')      
      .then(res => {
        this.metrics = res.data.data;
        // Set the companyPrimaryColor value
        this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');
        // Update the momentsSentChartData
        this.momentsSentChartData = [{ name: "Sparks sent", data: this.metrics.moments_sent_chart }];                    
        // Update the uniqueRecipientsChartData
        this.uniqueRecipientsChartData = [{ name: "Unique recievers", data: this.metrics.unique_recipients_chart }];
        // Update the pointsReceivedChartData
        this.pointsReceivedChartData = [{ name: "Coins received", data: this.metrics.points_received_chart }];        
        // Close the loader
        this.loadingData = false;                      
      })
      .catch(err => {
        console.error(err); 
      });       
    }
  },
  mounted: function() {         
    this.getMomentMetrics();
  }
}
</script>