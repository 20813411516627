<template>
  <div class="w-100 d-flex flex-wrap insights">
    <CCol :cols="showNPS ? 6 : 4" :xl="showNPS ? 6 : 4" :lg="showNPS ? 6 : 4" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <CRow>     
            <CCol cols="9" xl="9" lg="9" md="9" sm="9" class="text-left pt-0 pb-0">
              {{$t('measure.Sentiment_during_last_3_months')}}
            </CCol>
            <CCol cols="3" xl="3" lg="3" md="3" sm="3" class="text-right pt-0 pb-0">
              <i v-if="!loadingData" class="fas fa-question-circle help_icon" v-c-tooltip="{placement: 'left', content: $t('measure.Sentiment_during_last_3_months_help')}"/>
            </CCol>            
          </CRow>
        </CCardHeader>
        <CCardBody v-bind:class="{'pt-0' : metrics.sentiment_score}">
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <div v-else class="h-100 d-flex flex-column align-items-center justify-content-center">
            <div v-if="metrics.sentiment_score" class="h-100 w-100">
              <div class="h-100 w-100 area_chart_container">
                <div class="d-flex flex-column align-items-center justify-content-center chart_count">
                  <Grade :grade="metrics.sentiment_score.average_score" :centered="false"/>
                  <ReachDigits :nDigit="metrics.sentiment_score.n_digit" :qDigit="metrics.sentiment_score.q_digit" class="mt-1"/>
                </div>                
                <AreaChart :areaChartSeries="sentimentScoreChartData" :xAxisType="null" yAxisType="decimal" :companyPrimaryColor="companyPrimaryColor"/>
              </div>
            </div>
            <div v-else class="text-center">
              <span>{{ $t('measure.not_enough_sentiment_score_data') }}</span>
            </div>
          </div>          
        </CCardBody>
      </CCard>
    </CCol>
    <CCol v-if="showNPS" cols="6" xl="6" lg="6" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="9" xl="9" lg="9" md="9" sm="9" class="text-left pt-0 pb-0">
              {{$t('measure.eNPS_during_last_3_months')}}
            </CCol>
            <CCol cols="3" xl="3" lg="3" md="3" sm="3" class="text-right pt-0 pb-0">
              <i v-if="!loadingData" class="fas fa-question-circle help_icon" v-c-tooltip="{placement: 'left', content: $t('measure.eNPS_during_last_3_months_help')}"/>
            </CCol>                  
          </CRow>       
        </CCardHeader>
        <CCardBody v-bind:class="{'pt-0' : metrics.enps.data_available}">
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <NetPromoterScore v-else :enps="metrics.enps"/>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :cols="showNPS ? 6 : 4" :xl="showNPS ? 6 : 4" :lg="showNPS ? 6 : 4" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="9" xl="9" lg="9" md="9" sm="9" class="text-left pt-0 pb-0">
              {{$t('measure.Engagement_during_last_3_months')}}
            </CCol>
            <CCol cols="3" xl="3" lg="3" md="3" sm="3" class="text-right pt-0 pb-0">
              <i v-if="!loadingData" class="fas fa-question-circle help_icon" v-c-tooltip="{placement: 'left', content: $t('measure.Engagement_during_last_3_months_help')}"/>
            </CCol>
          </CRow>       
        </CCardHeader>
        <CCardBody v-bind:class="{'pt-0' : metrics.engagement.data_available}">
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <SatisQuestionsEngagement v-else :engagement="metrics.engagement"/>       
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :cols="showNPS ? 6 : 4" :xl="showNPS ? 6 : 4" :lg="showNPS ? 6 : 4" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="9" xl="9" lg="9" md="9" sm="9" class="text-left pt-0 pb-0">
              {{$t('measure.Emoji_cloud_during_last_3_months')}}
            </CCol>
            <CCol cols="3" xl="3" lg="3" md="3" sm="3" class="text-right pt-0 pb-0">
              <i v-if="!loadingData" class="fas fa-question-circle help_icon" v-c-tooltip="{placement: 'left', content: $t('measure.Emoji_cloud_during_last_3_months_help')}"/>
            </CCol>            
          </CRow>       
        </CCardHeader>
        <CCardBody v-bind:class="{'pt-0' : metrics.emoji_cloud.data_available}">
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <EmojiCloud v-else :emojiCloud="metrics.emoji_cloud"/>
        </CCardBody>
      </CCard>
    </CCol>
  </div>  
</template>


<script>
import axios from 'axios'
import { CChartDoughnut } from '@coreui/vue-chartjs'

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import Grade from '@/components/insights/common/Grade.vue';
import ReachDigits from '@/components/insights/common/ReachDigits.vue';
import AreaChart from '@/components/insights/common/AreaChart.vue';

import NetPromoterScore from '@/components/insights/measure/NetPromoterScore.vue';
import EmojiCloud from '@/components/insights/measure/EmojiCloud.vue';
import SatisQuestionsEngagement from '@/components/insights/measure/SatisQuestionsEngagement.vue';

export default {
  name: 'MeasureMetrics',
  props: ['showNPS'],
  components: {
    CChartDoughnut,
    loadingSpinner,
    Grade,
    ReachDigits,
    AreaChart,
    NetPromoterScore,
    EmojiCloud,
    SatisQuestionsEngagement
  }, 
  data() {
    return {
      loadingData: false,
      metrics: {
        sentiment_score: {
          average_score: '0.0',
          n_digit: 0,
          q_digit: 0
        },
        emoji_cloud: { data_available: false },
        enps: { data_available: false },
        engagement: {
          distribution: { 
            data_available: false
          }
        }
      },      
      dateFrom: new Date(),
      dateTo: new Date(),
      companyPrimaryColor: null,
      sentimentScoreChartData: []      
    }
  },
  methods: {
    getMeasureMetrics() {
      // Start the loader
      this.loadingData = true;
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd")      
      // Get the employee
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/measure/' + dateFromAPI + '/' + dateToAPI)      
      .then(res => {
        this.metrics = res.data.data;
        // Set the companyPrimaryColor value
        this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');
        // Update the sentimentScoreChartData
        this.sentimentScoreChartData = [{ name: "Sentiment", data: this.metrics.sentiment_score_chart }];
        // Close the loader
        this.loadingData = false;                      
      })
      .catch(err => {
        console.error(err); 
      });
    }  
  },
  mounted: function() {
    // Set the default for dateFrom to today minus 90 days
    this.dateFrom.setDate(this.dateFrom.getDate() -90);
    // Set the default for dateFrom to today minus 1 year
    // this.dateFrom.setFullYear(this.dateFrom.getFullYear() - 1);    
    // Get the metrics     
    this.getMeasureMetrics();
  }
}
</script>