<template>
  <CRow v-if="dataAvailable" class="h-100 engagement">
    <CCol cols="12" xl="12" md="12" sm="12" class="pt-0 pb-0">
      <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
        <div ref="barChartContainer" class="h-100 w-100 d-flex align-items-center justify-content-center bar_chart_container" style="margin-left:-18.5px">
          <HorizontalBarChart :barChartSeries="barChartSeries"
                              :barChartWidth="barChartWidth"                             
                              :xAxisType="null" 
                              :xAxisMaxValue="engagementData.total_employees" 
                              :yAxisType="null" 
                              :companyPrimaryColor="companyPrimaryColor">
          </HorizontalBarChart>
        </div>
        <div>
          <span>{{$t('common.Total_users')}}: {{engagementData.total_employees}}</span>          
        </div>
      </div>
    </CCol>
  </CRow>
  <CRow v-else class="h-100">
    <CCol cols="12" xl="12" sm="12" class="pt-0 pb-0">
      <div class="h-100 w-100 d-flex align-items-center justify-content-center">
        <span class="text-center">{{ $t('measure.Not_enough_engagement_data') }}</span>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { CChartDoughnut } from '@coreui/vue-chartjs'
import HorizontalBarChart from '@/components/insights/common/HorizontalBarChart.vue';

export default {
  name: 'SatisQuestionsEngagement',
  props: ['engagement'],
  components: {
    CChartDoughnut,
    HorizontalBarChart
  },
  watch: {
    $props: {
      handler() {
        if(this.engagement.data_available) {
          this.setEngagementData();
        }  
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      dataAvailable: false,
      engagementData: {},
      engagementChartData: {        
        datasets: [],
        labels: [],
        options: {
          responsive: false,
          maintainAspectRatio: true,
          legend: {display: false},
          tooltips: {enabled: false},
          hover: {mode: null},
          aspectRatio: 1
        }
      },
      companyPrimaryColor: null,
      barChartWidth: '500px',
      barChartSeries: []   
    }
  },
  methods: {
    setEngagementData() { 
      this.engagementData = this.engagement;
      // Set the companyPrimaryColor value
      this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');      
      // Update the barChartSeries
      this.barChartSeries = [{
        name: this.$t('measure.Not_so_much_engaged'),
        data: [this.engagementData.distribution.not_so_much_engaged]
      }, {
        name: this.$t('measure.Engaged'),
        data: [this.engagementData.distribution.engaged]
      }, {
        name: this.$t('measure.Actively_engaged'),
        data: [this.engagementData.distribution.actively_engaged]
      }];        
      // Update the dataAvailable value
      this.dataAvailable = true;

      setTimeout(function() {
        let barChartContainerWidth = this.$refs.barChartContainer.offsetWidth;
        this.barChartWidth = barChartContainerWidth < 500 ? barChartContainerWidth + 'px' : '500px';        
      }.bind(this), 100)
    }
  } 
}
</script>