<template>
  <apexchart v-if="this.chartOptions.colors.length > 0"
             width="100%"
             height="200px"
             :options="chartOptions"
             :series="areaChartSeries">
  </apexchart>
</template>

<script>

export default {
  name: 'AreaChart',
  props: ['areaChartSeries', 'xAxisType', 'yAxisType', 'companyPrimaryColor'],
  data() {
    return {    
      chartOptions: {
        chart: {
          type: 'area',
          width: '100%',
          animations: {
            initialAnimation: { enabled: true },
            speed: 200
          },
          toolbar: {
            show: false // Dit verwijdert de toolbar waar de zoomknoppen zich bevinden
          },
          zoom: {
            enabled: false // Dit schakelt de zoomfunctie volledig uit
          },
          selection: {
            enabled: false // Dit schakelt de selectiefunctie uit
          }
        },
        stroke: {
          show: false,
          curve: 'smooth',
        },
        colors: [],
        legend: { show: false },
        dataLabels: { enabled: false },
        plotOptions: {
          bar: {
            distributed: true,
            columnWidth: '50%'
          }
        },
        xaxis: {
          labels: {
            show: false, // Verberg de labels van de X-as
            style: {
              fontSize: '12px',
              fontFamily: 'Manrope, sans-serif',
              fontWeight: 400
            }
          },
          axisTicks: {
            show: false // Optioneel: Verberg de ticks (streepjes) op de X-as
          }
        },
        yaxis: {
          min: 0,
          tickAmount: 1,
          forceNiceScale: true,
          labels: {
            show: true, // Verberg de labels van de Y-as
            style: {
              fontSize: '12px',
              fontFamily: 'Manrope, sans-serif',
              fontWeight: 400
            },
            axisTicks: {
              show: true // Optioneel: Verberg de ticks (streepjes) op de Y-as
            }
          }
        },
        grid: {
          borderColor: 'transparent',
        },
        tooltip: {
          enabled: false, // Schakel tooltips volledig uit
          x: { show: false }
        }
      }
    }
  },
  mounted:function() {
    // Set the color of the chart
    this.chartOptions.colors.push(this.companyPrimaryColor);
    // Set the type of the x axis
    this.chartOptions.xaxis.type = this.xAxisType;

    if(this.xAxisType === 'datetime') {
      this.chartOptions.tooltip.x.format = 'yyyy-MM';
    }

    if(this.yAxisType === 'decimal') {
      this.chartOptions.yaxis.labels.formatter = function(val) {
        return val.toFixed(1)
      }
    } else {
      this.chartOptions.yaxis.labels.formatter = function(val) {
        return val.toFixed(0)
      }
    }
  }
}
</script>