<template>
  <apexchart :width="barChartWidth"
             height="200px"
             :options="chartOptions"
             :series="barChartSeries">
  </apexchart>
</template>

<script>

export default {
  name: 'BarChart',
  props: ['barChartSeries', 'barChartWidth', 'xAxisType', 'xAxisMaxValue', 'yAxisType', 'companyPrimaryColor'],
  watch: {
    $props: {
      handler() {
        this.chartOptions.theme.monochrome.color = this.companyPrimaryColor;
        // Enable the legend if necessary
        if(this.barChartSeries.length > 0) this.chartOptions.legend.show = true;
        // Set the type of the x axis
        this.chartOptions.xaxis.type = this.xAxisType;
        // Set the max value of the x axis
        this.chartOptions.xaxis.max = this.xAxisMaxValue;
                
        if(this.xAxisType === 'datetime') {
          this.chartOptions.tooltip.x.format = 'yyyy-MM-dd';
        }

        if(this.yAxisType === 'decimal') {
          this.chartOptions.yaxis.labels.formatter = function(val) {
            return val.toFixed(1)
          }
        } else {
          this.chartOptions.yaxis.labels.formatter = function(val) {
            return val.toFixed(0)
          }
        }
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          animations: {
            initialAnimation: { enabled: true },
            speed: 200
          },
          toolbar: {
            show: false // Dit verwijdert de toolbar waar de zoomknoppen zich bevinden
          },
          zoom: {
            enabled: false // Dit schakelt de zoomfunctie volledig uit
          },
          selection: {
            enabled: false // Dit schakelt de selectiefunctie uit
          }
        },
        theme: { 
          monochrome: { enabled: true }
        },
        legend: { show: false },
        dataLabels: { // Modified dataLabels section from step 1 is here
          enabled: true,
          style: {
            fontSize: '14px',
            fontFamily: 'Manrope, sans-serif',
            fontWeight: 400,
            colors: ['#FFFFFF']
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: false,
            columnWidth: '50%'
          }
        }, 
        stroke: {
          show: true,
          width: 5,
          colors: ['transparent']
        },               
        xaxis: {
          categories: [0],  // Verberg de labels van de X-as         
          labels: {
            show: false, // Verberg de labels van de X-as
            style: {
              fontSize: '12px',
              fontFamily: 'Manrope, sans-serif',
              fontWeight: 400
            }
          },
          axisTicks: {
            show: false // Optioneel: Verberg de ticks (streepjes) op de X-as
          }        
        },
        yaxis: {
          tickAmount: 1,
          forceNiceScale: true,
          labels: {
            show: false, // Verberg de labels van de Y-as
            style: {
              fontSize: '12px',
              fontFamily: 'Manrope, sans-serif',
              fontWeight: 400
            }                 
          },
          axisTicks: {
            show: false // Optioneel: Verberg de ticks (streepjes) op de Y-as
          }          
        },
        tooltip: {
          enabled: false, // Schakel tooltips volledig uit
          x: { show: false }
        }      
      }    
    }
  }
}
</script>