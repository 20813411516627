<template>
  <div v-if="companyDataLoaded">
    <!-- Onboarding Checklist / Schedule Contact Call -->
    <CRow v-if="!companyData.get_started_completed">
      <CCol cols="12" lg="12" class="p-0">
        <b-collapse class="panel dashboard show" :open="true">
          <div slot="trigger" slot-scope="props" class="panel-heading p-0" role="button">
            <CRow class="w-100 m-0">
              <CCol cols="12" lg="12" class="d-flex" v-bind:class="{'pb-0' : props.open}">
                <h1 class="dashboard_page_title mb-0">{{ $t('dash.onboarding_checklist') }}</h1>
                <i class="fas" v-bind:class="{'fa-chevron-up' : props.open, 'fa-chevron-down' : !props.open}"/>
              </CCol>              
            </CRow>
          </div>
          <div class="panel-block p-0">
            <CRow class="w-100 m-0">
              <OnboardingChecklist :checklist="onboardingChecklist" :modules="modules"/>
            </CRow>
          </div>
        </b-collapse>
      </CCol>
    </CRow>    

    <!-- Announcements -->
    <CRow>
      <CCol cols="12" lg="12" class="p-0">
        <Announcements/>
      </CCol>
    </CRow>

    <!-- Invalid Payment Mandate for Recurring Payments -->
    <CRow v-if="companyData.payment_setup_completed && companyData.payment_type_tag === 'recurring' && companyData.payment_mandate_set && !companyData.payment_mandate_valid">
      <CCol cols="12" lg="12" class="p-0">
        <InvalidMandate/>
      </CCol>
    </CRow>

    <!-- Measure Insights -->
    <CRow v-if="modules.satisquestions == 1 && (checkPermission('measure.sq.sqinsights') || checkPermission('measure.sq.subpillarinsights'))">
      <CCol cols="12" lg="12" class="p-0">
        <b-collapse class="panel dashboard show" :open="true">
          <div slot="trigger" slot-scope="props" class="panel-heading p-0" role="button">
            <CRow class="w-100 m-0">
              <CCol cols="12" lg="12" class="d-flex" v-bind:class="{'pb-0' : props.open}">
                <h1 class="dashboard_page_title mb-0">{{ $t('dash.measure2') }}</h1>
                <i class="fas" v-bind:class="{'fa-chevron-up' : props.open, 'fa-chevron-down' : !props.open}"/>
              </CCol>              
            </CRow>
          </div>
          <div class="panel-block p-0">
            <CRow v-if="checkPermission('measure.sq.sqinsights')" class="w-100 m-0">
              <MeasureMetrics :showNPS="modules.enps == 1 ? true : false"/>
            </CRow>
            <CRow v-if="checkPermission('measure.sq.subpillarinsights')" class="w-100 m-0">
              <MeasureHighlights/>
            </CRow>            
          </div>
        </b-collapse>
      </CCol>
    </CRow>

    <!-- loyalty Insights -->
    <CRow v-if="modules.employee_loyalty_programme == 1 && !checkRole('loyalty') && 
                ( checkPermission('loyalty.insights.employeespoints') || 
                  checkPermission('loyalty.wallet') || 
                  checkPermission('loyalty.insights.moments') ||
                  checkPermission('loyalty.insights.userpoints')
                )">
      <CCol cols="12" lg="12" class="p-0">
        <b-collapse class="panel dashboard show" :open="true">
          <div slot="trigger" slot-scope="props" class="panel-heading p-0" role="button">
            <CRow class="w-100 m-0">
              <CCol cols="12" lg="12" class="d-flex" v-bind:class="{'pb-0' : props.open}">
                <h1 class="dashboard_page_title mb-0">{{ $t('dash.spark_moments') }}</h1>
                <i class="fas" v-bind:class="{'fa-chevron-up' : props.open, 'fa-chevron-down' : !props.open}"/>
              </CCol>              
            </CRow>
          </div>
          <div class="panel-block p-0">
            <CRow v-if="(modules.show_employee_coins_amount === 1 && checkPermission('loyalty.insights.employeespoints')) || (modules.show_company_wallet === 1 && checkPermission('loyalty.wallet'))" class="w-100 m-0">
              <EmployeesPoints v-if="modules.show_employee_coins_amount === 1 && checkPermission('loyalty.insights.employeespoints')" 
                                :cols="(modules.show_company_wallet === 1 && checkPermission('loyalty.wallet')) ? 6 : 12">
              </EmployeesPoints>
              <CompanyWallet v-if="modules.show_company_wallet === 1 && checkPermission('loyalty.wallet')" 
                              :cols="(modules.show_employee_coins_amount === 1 && checkPermission('loyalty.insights.employeespoints')) ? 6 : 12">
              </CompanyWallet>
            </CRow>

            <CRow v-if="checkPermission('loyalty.insights.moments')" class="w-100 m-0">
              <LoyaltyMomentMetrics/>
            </CRow>

            <CRow v-if="checkPermission('loyalty.insights.moments')" class="w-100 m-0">
              <LastSentLoyaltyMoments :platformPermissions="platformPermissions" :platformRoles="platformRoles" :modules="modules"/>
            </CRow>

            <CRow v-if="checkPermission('loyalty.insights.moments') || checkPermission('loyalty.insights.userpoints')" class="w-100 m-0">
              <MomentsReceivedLeaderboard v-if="checkPermission('loyalty.insights.moments')"/>
              <MomentsSentLeaderboard v-if="checkPermission('loyalty.insights.moments')"/>
            </CRow>
          </div>
        </b-collapse>
      </CCol>
    </CRow>

    <!-- Connect Insights -->
    <CRow v-if="modules.social_wall == 1 && (checkPermission('connect.sw.postinsights') || checkPermission('connect.topics.topicinsights'))">
      <CCol cols="12" lg="12" class="p-0">
        <b-collapse class="panel dashboard show" :open="true">
          <div slot="trigger" slot-scope="props" class="panel-heading p-0" role="button">
            <CRow class="w-100 m-0">
              <CCol cols="12" lg="12" class="d-flex" v-bind:class="{'pb-0' : props.open}">
                <h1 class="dashboard_page_title mb-0">{{ $t('dash.connect2') }}</h1>
                <i class="fas" v-bind:class="{'fa-chevron-up' : props.open, 'fa-chevron-down' : !props.open}"/>
              </CCol>              
            </CRow>
          </div>
          <div class="panel-block p-0">
            <CRow v-if="checkPermission('connect.sw.postinsights')" class="w-100 m-0">
              <ConnectMetrics/>
            </CRow>
            <CRow v-if="checkPermission('connect.sw.postinsights')" class="w-100 m-0">
              <SocialWallPostsEngagement/>
            </CRow>
            <CRow v-if="checkPermission('connect.topics.topicinsights')" class="w-100 m-0">
              <TopicsChart/>
            </CRow>            
          </div>
        </b-collapse>
      </CCol>
    </CRow>

    <!-- Knowledge Insights -->
    <CRow v-if="modules.social_kb == 1 && checkPermission('knowledge.insights.knowledge')">
      <CCol cols="12" lg="12" class="p-0">
        <b-collapse class="panel dashboard show" :open="true">
          <div slot="trigger" slot-scope="props" class="panel-heading p-0" role="button">
            <CRow class="w-100 m-0">
              <CCol cols="12" lg="12" class="d-flex" v-bind:class="{'pb-0' : props.open}">
                <h1 class="dashboard_page_title mb-0">{{ $t('dash.knowledge2') }}</h1>
                <i class="fas" v-bind:class="{'fa-chevron-up' : props.open, 'fa-chevron-down' : !props.open}"/>
              </CCol>              
            </CRow>
          </div>
          <div class="panel-block p-0">
            <CRow class="w-100 m-0">
              <MostViewedItems/>
              <MostViewedSubcategories/>
            </CRow>           
            <CRow class="w-100 m-0">
              <RecentlyAddedItems/>
            </CRow>
          </div>
        </b-collapse>
      </CCol>
    </CRow>

    <!-- Learn Insights -->
    <CRow v-if="modules.academy == 1 && (checkPermission('learn.insights.ldinsights') || checkPermission('learn.insights.onboarding'))">
      <CCol cols="12" lg="12" class="p-0">
        <b-collapse class="panel dashboard show" :open="true">
          <div slot="trigger" slot-scope="props" class="panel-heading p-0" role="button">
            <CRow class="w-100 m-0">
              <CCol cols="12" lg="12" class="d-flex" v-bind:class="{'pb-0' : props.open}">
                <h1 class="dashboard_page_title mb-0">{{ $t('dash.learn2') }}</h1>
                <i class="fas" v-bind:class="{'fa-chevron-up' : props.open, 'fa-chevron-down' : !props.open}"/>
              </CCol>              
            </CRow>
          </div>
          <div class="panel-block p-0">
            <CRow v-if="checkPermission('learn.insights.ldinsights')" class="w-100 m-0">
              <CoursePerformance/>
              <RecentlyAddedCourses/>
            </CRow>
            <CRow v-if="checkPermission('learn.insights.onboarding') && modules.platform_first_onboard == 1" class="w-100 m-0">
              <Onboarding/>
            </CRow>                      
          </div>
        </b-collapse>
      </CCol>
    </CRow>

    <!-- Loyalty Employees -->
    <div v-if="modules.employee_loyalty_programme == 1 && checkRole('loyalty')">        
      <CRow>
        <CCol cols="12" lg="12" class="p-0">
          <loyaltyMomentsReminder :platformPermissions="platformPermissions" :showHeader="false" :showWizardButton="false"/>
        </CCol>
      </CRow>
      <CRow>
        <CCol cols="12" lg="12" class="p-0">
          <b-collapse class="panel dashboard show" :open="true">
            <div slot="trigger" slot-scope="props" class="panel-heading p-0" role="button">
              <CRow class="w-100 m-0">
                <CCol cols="12" lg="12" class="d-flex" v-bind:class="{'pb-0' : props.open}">
                  <h1 class="dashboard_page_title mb-0">{{ $t('dash.spark_moments') }}</h1>
                  <i class="fas" v-bind:class="{'fa-chevron-up' : props.open, 'fa-chevron-down' : !props.open}"/>
                </CCol>              
              </CRow>
            </div>
            <div class="panel-block p-0">
              <CRow class="w-100 m-0">
                <CCol cols="12" lg="12">
                  <loyaltyMoments :platformPermissions="platformPermissions" :platformRoles="platformRoles" :modules="modules" :showHeader="false"/>
                </CCol>
              </CRow>
            </div>
          </b-collapse>
        </CCol>
      </CRow>
      <CRow v-if="['explorer', 'business', 'enterprise'].includes(productLicenses.loyalty_license_tag)">
        <CCol cols="12" lg="12" class="p-0">
          <b-collapse class="panel dashboard show" :open="true">
            <div slot="trigger" slot-scope="props" class="panel-heading p-0" role="button">
              <CRow class="w-100 m-0">
                <CCol cols="12" lg="12" class="d-flex" v-bind:class="{'pb-0' : props.open}">
                  <h1 class="dashboard_page_title mb-0">{{ $t('dash.Coins_updates') }}</h1>
                  <i class="fas" v-bind:class="{'fa-chevron-up' : props.open, 'fa-chevron-down' : !props.open}"/>
                </CCol>              
              </CRow>
            </div>
            <div class="panel-block p-0">
              <CRow class="w-100 m-0">
                <CCol cols="12" lg="12">
                  <loyaltyPointsUpdates :platformPermissions="platformPermissions" :showHeader="false" :itemsPerPage="5" :productLicenses="productLicenses"/>
                </CCol>
              </CRow>
            </div>
          </b-collapse>
        </CCol>
      </CRow>        
    </div>

    <!-- Organization Insights -->
    <CRow v-if="checkPermission('insights.employees.metrics')">
      <CCol cols="12" lg="12" class="p-0">
        <b-collapse class="panel dashboard show" :open="true">
          <div slot="trigger" slot-scope="props" class="panel-heading p-0" role="button">
            <CRow class="w-100 m-0">
              <CCol cols="12" lg="12" class="d-flex" v-bind:class="{'pb-0' : props.open}">
                <h1 class="dashboard_page_title mb-0">{{ $t('dash.organization') }}</h1>
                <i class="fas" v-bind:class="{'fa-chevron-up' : props.open, 'fa-chevron-down' : !props.open}"/>
              </CCol>              
            </CRow>
          </div>
          <div class="panel-block p-0">
            <CRow class="w-100 m-0">
              <EmployeeMetrics/>
            </CRow>
          </div>
        </b-collapse>
      </CCol>
    </CRow>
  </div>
</template>

<script>
// System imports
import Announcements from '@/components/dashboard/system/Announcements.vue';
import OnboardingChecklist from '@/components/dashboard/system/OnboardingChecklist.vue';
// Employees imports
import EmployeeMetrics from '@/components/dashboard/employees/EmployeeMetrics.vue';
// Measure imports
import MeasureMetrics from '@/components/dashboard/measure/MeasureMetrics.vue';
import MeasureHighlights from '@/components/dashboard/measure/MeasureHighlights.vue';
// Connect imports
import ConnectMetrics from '@/components/dashboard/connect/ConnectMetrics.vue';
import SocialWallPostsEngagement from '@/components/dashboard/connect/SocialWallPostsEngagement.vue';
import TopicsChart from '@/components/dashboard/connect/TopicsChart.vue';
// Knowledge imports
import MostViewedItems from '@/components/dashboard/knowledge/MostViewedItems.vue';
import MostViewedSubcategories from '@/components/dashboard/knowledge/MostViewedSubcategories.vue';
import RecentlyAddedItems from '@/components/dashboard/knowledge/RecentlyAddedItems.vue';
// Learn imports
import CoursePerformance from '@/components/dashboard/learn/CoursePerformance.vue';
import Onboarding from '@/components/dashboard/learn/Onboarding.vue';
import RecentlyAddedCourses from '@/components/dashboard/learn/RecentlyAddedCourses.vue';
// Loyalty imports
import CompanyWallet from '@/components/dashboard/loyalty/CompanyWallet.vue';
import EmployeesPoints from '@/components/dashboard/loyalty/EmployeesPoints.vue';
import LastSentLoyaltyMoments from '@/components/dashboard/loyalty/LastSentLoyaltyMoments.vue';
import MomentsReceivedLeaderboard from '@/components/dashboard/loyalty/MomentsReceivedLeaderboard.vue';
import MomentsSentLeaderboard from '@/components/dashboard/loyalty/MomentsSentLeaderboard.vue';
import LoyaltyMomentMetrics from '@/components/dashboard/loyalty/LoyaltyMomentMetrics.vue';
// Payments imports
import InvalidMandate from '@/components/payments/InvalidMandate.vue';
// Loyalty Employee imports
import loyaltyMoments from '@/components/loyalty/loyaltyMoments.vue';
import loyaltyPointsUpdates from '@/components/loyalty/loyaltyPointsUpdates.vue';
import loyaltyMomentsReminder from '@/components/loyalty/loyaltyMomentsReminder.vue';

import axios from 'axios'

export default {
  name: 'Dashboard',
  components: {
    // System
    Announcements,
    OnboardingChecklist,
    // Employees
    EmployeeMetrics,    
    // Measure
    MeasureMetrics,
    MeasureHighlights,
    // Connect    
    ConnectMetrics,
    SocialWallPostsEngagement,
    TopicsChart,
    // Knowledge
    MostViewedItems,
    MostViewedSubcategories,
    RecentlyAddedItems,
    // Learn
    CoursePerformance,
    Onboarding,
    RecentlyAddedCourses,
    // Loyalty
    CompanyWallet,
    EmployeesPoints,
    LastSentLoyaltyMoments,
    MomentsReceivedLeaderboard,
    MomentsSentLeaderboard,
    LoyaltyMomentMetrics,
    // Payments
    InvalidMandate,
    // Loyalty Employee
    loyaltyMoments,
    loyaltyPointsUpdates,
    loyaltyMomentsReminder
  },
  data () {
    return {
      productLicenses: {
        loyalty_licence_tag: null
      },
      modules: {
        satisquestions: 0,
        burning_matters: 0,
        social_wall: 0,
        employee_loyalty_programme: 0,
        social_kb: 0
      },
      companyData: {
        get_started_completed: false,
      },
      companyDataLoaded: false,
      onboardingChecklist: {
        company_employee_count: 0,
        company_look_and_feel_set: false,
        company_loyalty_event_count: 0,
        user_2fa_setup: false
      },      
      platformPermissions: [],
      platformRoles: []
    }
  },
  methods: {
    getCompanyData() {
      // Get the company details
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {
        this.companyData = res.data.data;
        // Update the companyDataLoaded value
        this.companyDataLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getOnboardingChecklist() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/onboarding/checklist')
      .then(res => {
        this.onboardingChecklist = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {    
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    }    
  },
  async mounted() {
    this.checkModules();
    this.getPlatformPermissions();
    this.getPlatformRoles();

    this.productLicenses = await this.fetchProduuctLicenseData();

    this.getCompanyData();
    this.getOnboardingChecklist();
  }
}
</script>
